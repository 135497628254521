@font-face {
    font-family: 'GT-Walsheim-Pro-Regular';
    src: local('GT-Walsheim-Pro-Regular'), url(../design/fonts/GT-Walsheim-Pro-Regular.woff) format('woff');
}
@font-face {
    font-family: 'GT-Walsheim-Pro-Bold-Oblique';
    src: local('GT-Walsheim-Pro-Bold-Oblique'), url(../design/fonts/GT-Walsheim-Pro-Bold-Oblique.woff) format('woff');
}
@font-face {
    font-family: 'GT-Walsheim-Pro-Bold';
    src: local('GT-Walsheim-Pro-Bold'), url(../design/fonts/GT-Walsheim-Pro-Bold.woff) format('woff');
}
@font-face {
    font-family: 'GT-Walsheim-Pro-Light-Oblique';
    src: local('GGT-Walsheim-Pro-Light-Oblique'), url(../design/fonts/GT-Walsheim-Pro-Light-Oblique.woff) format('woff');
}
@font-face {
    font-family: 'GT-Walsheim-Pro-Light';
    src: local('GT-Walsheim-Pro-Light'), url(../design/fonts/GT-Walsheim-Pro-Light.woff) format('woff');
}
@font-face {
    font-family: 'GT-Walsheim-Pro-Regular-Oblique';
    src: local('GT-Walsheim-Pro-Regular-Oblique'), url(../design/fonts/GT-Walsheim-Pro-Regular-Oblique.woff) format('woff');
}

