.widget-block {
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;
}

.news-item {
    display: flex;
}

.news-widget-image {
    height: 100%;
    width: 200px;
    margin-right: 0.5rem;
    border-radius: 5px;
}

.news-text-box {
    display: flex;
    flex-direction: column;
    text-align: right;

    b {
        margin-bottom: 0.5rem;
    }
}
