// DXC Colors
// add more colors as needed from the below url
// https://my.dxc.com/brand-guide/guidelines/dxc-visual-approach/color.html
:root {
    //dxc colors
    --dxc-white: #ffffff;
    --dxc-black: #000000;
    --dxc-purple: #6f2c91;
    --dxc-purple2: #7a53a4;
    --dxc-aqua: #28aee4;
    --dxc-bright-teal: #00968F;
    --dxc-blue: #0067b3;
    --dxc-light-gray: #d9d9d9;
    --dxc-dark-gray: #666666;
    --dxc-green: #97be35;
    --dxc-yellow: #f7cf2b;
    --dxc-orange: #f38f20;
    --dxc-lime: #d7df23;

    //application colors
    --app-black-transparent: rgba(0, 0, 0, 0.4);
}
