.sign-in-header {
    text-align: center;
}

.login-error-card {
    margin-bottom: 40px;
}

.right-triangle {
    --size: 60vmin;
    --halfsize: 30vmin;
    position: absolute;
    top: 10%;
    left: 0;
    width: 0;
    height: 0;
    border-top: var(--halfsize) solid transparent;
    border-bottom: var(--halfsize) solid transparent;
    border-left: var(--size) solid rgb(0, 0, 0);
}

.left-triangle {
    --size: 60vmin;
    --halfsize: 30vmin;
    position: absolute;
    top: 40%;
    right: 0;
    width: 0;
    height: 0;
    border-top: var(--halfsize) solid transparent;
    border-bottom: var(--halfsize) solid transparent;
    border-right: var(--size) solid rgb(111, 44, 145);
}

.login-page-container {
    background-color: var(--dxc-light-gray);
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
}

.login-form-container {
    background-color: white;
    padding: 25px;
    border: 2px solid var(--dxc-dark-gray);
    border-radius: 3px;
}

.sign-in-btn {
    margin-bottom: 21px;
}
