.background-info-layover {
    transition: 0.4s;
    opacity: 0;
}

.background-info-layover:hover {
    opacity: 1;
}

.background-image-status-indicator {
    transition: 0.4s;
    opacity: 1;
}

.background-image-status-indicator:hover {
    opacity: 0;
}