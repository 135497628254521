@font-face {
    font-family: 'OpenSans-Regular';
    src: url('./assets/fonts/OpenSans-Regular.ttf');
}

// body {
//     margin:0;
//     background-color:#293742;
// }

//Used on child components as on button icons, where we
//want to prevent the child to be a target.

@mixin myStuff {
    display: flex;
    flex-direction: row;
}

.floor-pointer-none {
    pointer-events: none;
}

.floor-main {
    font-family: OpenSans-Regular, Arial !important;
}

.floor-navbar-button {
    margin-right: 15px;
    height: 50px;
    width: 150px;
    font-weight: 600;
}

.floor-navbar-button:focus {
    outline: none;
}

.floor-navbar-button-danger {
    height: 50px;
}

.floor-navbar-button-danger:focus {
    outline: none;
}

.mapboxgl-popup div {
    color: #182026;
}

.floor-navbar {
    height: 80px !important;
    display: flex;
    align-items: center;
}

.floor-navbar-group-right {
    margin-left: auto;
}

.floor-time {
    text-align: left !important;
    width: 130px;
    font-weight: 400 !important;
}

.floor-date {
    margin-left: 20px !important;
    font-weight: 400 !important;
}

.floor-weather {
    padding-left: 0 !important;
    font-weight: 400 !important;
}

.floor-logo {
    height: 75px;
    margin-right: 25px;
}

//Related to Alert
.floor-alert-screen {
    position: fixed;
    display: flex;
    height: 100vh;
    width: 100%;
    z-index: 9999;
    background-color: rgba($color: red, $alpha: 0.5);
    align-items: center;
    justify-content: center;
}

.floor-alert-screen-message {
    position: absolute;
    display: flex;
    width: calc(100vw / 3);
    height: calc(100vh / 3);
    background-color: white;
    align-items: center;
    color: black;
    text-align: center;
}

.floor-navbar-button-danger {
    padding-left: 5px !important;
}

// Widgets on the General screen
.floor-widget-grid {
    height: 100%;
    padding-bottom: 15px;
}

.floor-widget {
    min-height: calc((100vh - 230px) / 3);
    display: flex;
    text-align: center;
}

//Center view
.floor-center {
    height: calc(100vh - 160px);
    margin-right: 15px;
    margin-left: 15px;
}

.floor-full-center-view {
    padding-top: 15px;
    display: flex;
    justify-content: center;
}

.floor-full-center-view-card {
    height: calc(100vh - 190px);
    width: 100%;
    padding-left: 0px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 0px !important;
    display: flex;
}
.floor-full-center-view-fill {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: sticky;
}
.floor-full-center-view-left-side {
    height: 100%;
    width: 37%;
}

.floor-full-center-view-right-side {
    height: 100%;
    width: 63%;
    overflow: hidden;
}

.floor-full-center-view-item-list {
    width: 100%;
    height: 85%;
    overflow-y: auto;
    // border-right: 3px solid #394b59; //original
    // border-bottom: 3px solid #394b59; //original
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
}

.floor-full-center-view-item-card {
    position: relative;
}

.floor-full-center-view-item-button {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
}

.map-legend-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 10px;
}

.floor-full-center-view-item-collapse-header {
    font-weight: bold;
    border: 1px solid white;
    padding: 10px;
    margin-bottom: 20px;
}

.floor-full-center-view-item-button:focus {
    outline: none;
}

.floor-filter-button-group {
    display: flex !important;
    justify-content: center !important;
    margin-top: 10px;
}

.floor-filter-button:focus {
    outline: none;
}

.floor-center-button {
    opacity: 0.8;
    position: fixed;
    top: 80px;
    right: 0;
    height: calc(100vh - 160px);
    transition: 0.4s;
}
.floor-center-button-hover {
    opacity: 0.3;
    position: fixed;
    top: 80px;
    right: 0;
    height: calc(100vh - 160px);
    transition: 0.4s;
    min-width: 10px !important;
}

.present-progress-bar {
    position: fixed !important;
    bottom: 80px;
    transform: rotate(180deg);
}
//Scrollbar
/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px #394b59;
    border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #202b33;
    border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #182026;
}
