/* .weather-list {           **********************kept as fallback for old code
    height: 100%;
    margin-top: 1rem;
    overflow-y: scroll;
}

.weather-item {
    width: 97.5%;
    padding: 0  0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 5px;
    height: 75px;
    overflow-y: hidden;
    transition: 0.4s;
}

.weather-item:hover {
    filter: invert(0.15);
}

.weather-item > * {
    width: 25%;
    text-align: center;
    padding: 0;
    margin: 0;
}

.weather-item-icon {
    font-size: 2.5rem;
} */



.selected-weather-item-card {
    margin: 3rem;
    padding: 1rem;
    border-radius: 10px;
    transition: 0.4s;
}

.selected-weather-item-city {
    font-size: 4rem;
    line-height: 4rem;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
}

.selected-weather-item-date {
    font-size: 2rem;
}

.selected-weather-item-hero {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.selected-weather-item-icon {
    font-size: 7rem;
    margin: 0;
    margin-right: 3rem;
    padding: 0;
}

.selected-weather-item-temp {
    font-size: 3.5rem;
}

.selected-weather-item-desc {
    font-size: 2.5rem;
}

.selected-weather-item-details {
    width: 100%;
}

.selected-weather-item-details > div {
    width: 100%;
    display: flex;
}

.selected-weather-item-details > div > b {
    font-size: 1.25rem;
    min-width: 30ch;
    margin: 0.25rem 0;
}

.selected-weather-item-details > div > span {
    font-size: 1.25rem;
}





.weekday-weather-box {
    width: 100%;
    display: flex;
    padding-top: 2rem;
    position: relative;
    margin-top: 4rem;
    padding-left: 1rem;
}

.three-hour-weather-day-name {
    position: absolute;
    top: 0;
    left: 1rem;
    margin: 0;
    padding: 0;
}

.three-hour-weather-box {
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.125);
    padding: 1rem 2rem;
    cursor: pointer;
    transition: 0.4s;
}

.three-hour-weather-box:hover {
    transform: scale(1.025);
    box-shadow: 0 0 5px #aaa;
}

.three-hour-weather-time {
    padding: 0;
    margin: 0;
    margin-bottom: 0.5rem;
    font-weight: 700;
    pointer-events: none;
}

.three-hour-weather-icon {
    font-size: 3rem;
    padding: 0;
    margin: 0;
    margin-bottom: 0.5rem;
    pointer-events: none;
}

.three-hour-weather-temp {
    padding: 0;
    margin: 0;
    margin-bottom: 0.5rem;
    font-weight: 700;
    pointer-events: none;
}





