#circle-container-one {
    width: 60px;
    height: 60px;
}

#circle-container-two {
    width: 60px;
    height: 60px;
}

#semi-container-one {
    width: 60px;
    height: 50px;
    margin-top: 1rem;
}
#semi-container-two {
    width: 60px;
    height: 50px;
    margin-top: 1rem;
}

.progress-text {
    bottom: 35% !important;
}

.performance-server {
    display: flex;
    flex-direction: column;
}

.performance-block {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.performance-item {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
