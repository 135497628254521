.videos-list-wrapper {
    position: relative;
    width: 100%;
    min-height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

.videos-list-line {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;

    .video-name {
        width: 25%;
        min-width: 25%;
        font-weight: 700;
        padding: 0;
        margin: 0;
        margin-right: 1rem;
    }

    .video-description {
        width: 50%;
        padding: 0;
        margin: 0;
        margin-right: 1rem;
    }

    .video-buttons {
        width: 25%;
        display: flex;
        justify-content: flex-end;
    }
}