.badge-parent {
    margin-right: 5px !important;
}

.badge {
    margin-right: 5px;
    margin-bottom: 20px;
    margin-left: 2px;
    color: #fff !important;
    background-color: #666666 !important;
}

.navbar-avatar {
    width: 32px;
    height: 32px;
    cursor: pointer;
    vertical-align: middle;
    border-radius: 50%;
}

.common-navbar > .bp3-navbar-group {
    height: 60px;
}

.common-navbar > .bp3-navbar-heading .badge-parent {
    padding-bottom: 5px;
}

a:hover {
    text-decoration: none !important;
}

.common-navbar.bp3-navbar {
    background-color: white !important;
    height: 60px !important;
}

.link {
    color: white !important;
}

.link:hover {
    color: white !important;
}

.navBarHeader {
    height: 60px;
    line-height: 60px;
    display: inline-block;
    position: fixed;
    left: 50%;
    margin-left: -125px;
    width: 250px;
    font-size: 16px;
}
