//Detailed sensor view Right Side
.single-sensor-widget-flex {
    display: grid;
    overflow-y: auto;
    height: calc(100% - 30px);
    grid-template-columns: auto auto auto auto;
    gap: 20px;
    margin: 15px;
}

.single-sensor-widget {
    height: 240px;
    padding: 10px;
}

.single-sensor-widget-card {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
}

// Sensor Widget Styles
.single-sensor-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.single-sensor-value {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}

// Sensors Filter view

.sensor-filter-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.sensor-filters {
    display: flex;
    justify-content: space-around;
}
.sensor-filter-category {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sensor-category-button-group {
    display: flex;
    flex-direction: column;
}

.sensor-filter-location {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.sensor-filter-floor {
    display: flex;
    flex-direction: column;
}

.sensor-filter-area {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sensor-area-button-group {
    display: flex;
    flex-wrap: wrap;
}

.sensor-floor-button-group {
    display: flex;
}
