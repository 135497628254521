.presentation-page {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.show-dialog-btn {
    cursor: pointer;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    width: 50px;
    min-width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #db3737;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-dialog-message {
    color: #d33d17;
    text-align: center;
}

.video-list-box {
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 5px;
}

.video-list-line {
    width: 100%;
    display: flex;
}

.video-name {
    font-size: 0.75rem;
    margin: 0 0.25rem 0.25rem 0;
    padding: 0;
    width: 100%;
    word-break: break-all;
}

.video-description {
    font-size: 0.75rem;
    margin: 0 0.25rem 0.25rem 0;
    padding: 0;
}

.video-controls-icon {
    margin: 0 0.25rem;
    cursor: pointer;
}
