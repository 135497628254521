.floor-icon-alarm {
    display: block;
    background-image: url('./assets/icons/interface/alarm.svg');
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center center;
}

.floor-icon-weather-main {
    display: block;
    background-image: url('./assets/icons/content/weather/weather-main.svg');
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
}

.floor-icon-transport-bus {
    display: block;
    background-image: url('./assets/icons/content/transport/TrvlTransprt_Bus.svg');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.floor-icon-transport-train {
    display: block;
    background-image: url('./assets/icons/content/transport/TrvlTransprt_Train.svg');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.floor-icon-transport-plane {
    display: block;
    background-image: url('./assets/icons/content/transport/TrvlTransprt_Plane.svg');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.floor-icon-scooter {
    display: block;
    background-image: url('./assets/icons/content/scooter/scooter-svgrepo-com.svg');
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    color: #5f249f;
}
