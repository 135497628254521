.footer-text {
    color: black !important;
}

.footer-contact {
    position: fixed;
    right: 15px;
    bottom: 13px;
}

.footer-contact a {
    color: black !important;
}
