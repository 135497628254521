.manage-widget-screen-wrapper {
    min-height: 100%;
    width: 100%;
    user-select: none
}

.manage-widgets-title {
    padding: 0; 
    margin: 0; 
    user-select: 'none';
}

.grid-preview {
    width: 100%;
    height: 500px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr);
    background: #666;
    grid-gap: 10px;
    position: relative;
    color: white;
    padding: 10px;
}

.widget-name {
    pointer-events: none;
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
}

.widget-dragger {
    position: absolute;
    top: 0;
    left: 0;
    cursor: grab;
}

.remove-widget-btn {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

.resize-button-group {
    position: absolute;
    user-select: none;
}

.resize-button-group > * {
    cursor: pointer;
}

.resize-up-buttons {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

.resize-down-buttons {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.resize-left-buttons {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.resize-right-buttons {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.free-field {
    
}