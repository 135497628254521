/* INTRO 

Please use the examples below to override the BP classes
into the DXC brand guidelines.
Link to our Digital guidelines
https://my.dxc.com/brand-guide/guidelines/dxc-visual-approach/Digital.html */

/* 
EXAMPLE OF BP3 BUTTON OVERRIDE FOR DXC PURPOSES 
notice that we don't create a new class rether we override (wildcard selectors) the bp3 ones from blueprint 
*/

/* NORMAL */
// [class*='bp3-button']:not([class*='bp3-button-text']) {
//     background-color: var(--dxc-purple) !important;
//     background-image: none !important;
//     border-radius: 0 !important;
//     color: var(--dxc-white) !important;
//     font-family: GT-Walsheim-Pro-Bold;
//     font-size: 20px !important;
//     transition: background-color 100ms ease !important;
//     padding-left: 50px !important;
//     padding-right: 50px !important;
//     height: 60px !important;
//     text-transform: uppercase;
//     text-align: center !important;
// }

// /* HOVER */
// [class*='bp3-button']:hover:not([class*='bp3-button-text'], [class*='bp3-disabled']) {
//     background-color: var(--dxc-white) !important;
//     background-image: none !important;
//     border-radius: 0 !important;
//     color: var(--dxc-black) !important;
//     font-family: GT-Walsheim-Pro-Bold;
//     font-size: 20px !important;
//     transition: background-color 100ms ease !important;
//     padding-left: 50px !important;
//     padding-right: 50px !important;
//     height: 60px !important;
//     text-transform: uppercase;
//     text-align: center !important;
//     border: 2px solid var(--dxc-purple) !important;
// }

// /* DISABLED */
// [class*='bp3-button bp3-disabled']:not([class*='bp3-button-text']) {
//     background-color: var(--dxc-light-gray) !important;
//     background-image: none !important;
//     border-radius: 0 !important;
//     color: var(--dxc-dark-gray) !important;
//     font-family: GT-Walsheim-Pro-Bold;
//     font-size: 20px !important;
//     transition: background-color 100ms ease !important;
//     padding-left: 50px !important;
//     padding-right: 50px !important;
//     height: 60px !important;
//     text-transform: uppercase;
//     text-align: center !important;
//     border: 2px solid var(--dxc-black) !important;
// }

// .adminAppRoot .bp3-dark {
//     background-color: black !important;
//     color: white !important;
// }

// .adminAppRoot .bp3-menu {
//     background-color: white !important;
//     border: 2px solid black !important;
//     color: black !important;
// }

// .adminAppRoot [class*='bp3-menu-item'] .bp3-menu .bp3-heading {
//     color: black !important;
// }

// .adminAppRoot [class*='bp3-menu-item']:hover {
//     background-color: #f5f5f5 !important;
// }

// .adminAppRoot [class*='bp3-menu-item'] .bp3-icon svg {
//     fill: black !important;
// }

// .adminAppRoot .bp3-popover2-arrow svg {
//     fill: black !important;
// }

// .adminAppRoot .bp3-popover2-arrow path {
//     fill: black !important;
// }

// .adminAppRoot .bp3-popover.bp3-minimal.bp3-submenu:hover {
//     background-color: transparent !important;
// }

/* EXAMPLES OF BP3 HEADING OVERRIDES FOR DXC PURPOSES */
/* [class*='bp3-heading'] {
    font-family: GT-Walsheim-Pro-Regular, Arial !important;
} */

.adminAppRoot h1.bp3-heading {
    font-size: 64px !important;
    line-height: 68px !important;
}

.adminAppRoot h2.bp3-heading {
    font-size: 48px !important;
    line-height: 54px !important;
}

.adminAppRoot h3.bp3-heading {
    font-size: 30px !important;
    line-height: 40px !important;
}

.adminAppRoot h4.bp3-heading {
    font-size: 20px !important;
    line-height: 30px !important;
}

.adminAppRoot h5.bp3-heading {
    font-size: 16px !important;
    line-height: 26px !important;
}

.adminAppRoot h6.bp3-heading {
    font-size: 14px !important;
    line-height: 22px !important;
}

.adminAppRoot .page-content {
    // margin-top: 80px;
    // margin-bottom: 60px;
    margin-top: 60px;
    margin-bottom: 40px;
}

.adminAppRoot .content-flexbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
