// bp3-dark theme uses theese Themes
$dark-gray1: rgb(66, 66, 66);
$dark-gray2: rgb(55, 55, 55);
$dark-gray3: rgb(44, 44, 44);
$dark-gray4: rgb(33, 33, 33);
$dark-gray5: rgb(22, 22, 22);
$dark-tooltip-background-color: rgb(33, 33, 33);
$dark-tooltip-text-color: #ffffff;
$pt-dark-icon-color: #ffffff;
// Default(white classes)
$tooltip-text-color: #000000;
$tooltip-background-color: #ffffff;
$pt-text-color: black;

$pt-icon-color: #000000;
$menu-background-color: #ffffff;
$navbar-background-color: #ffffff;
$card-background-color: #ffffff;
$button-background-color: #ffffff;
$button-background-color-hover: #ffffff;
$button-background-color-active: #7a53a4;

// Progress Bar Color
$progress-head-color: #7a53a4;

//Bluprintjs <Tabs /> override
$pt-intent-primary: #7a53a4; // selected Tab background color
$tab-color-selected: black; // tag text color



@import '~@blueprintjs/core/src/blueprint.scss';

:root {
    --dark-card-fill: #{$dark-gray4};
    --light-card-fill: #{$card-background-color};
}
