.scooter-block {
    margin-top: 1rem;
    display: flex;
    width: 100%;
    min-width: 200px;
    flex-direction: column;
}

.scooter-detail {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.scooter-provider {
    font-size: large;
    display: flex;
    align-items: center;
}
.scooter-attributes {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.scooter-attribute {
    display: flex;
    align-items: center;
}
.scooter-prices {
    display: flex;
}
.scooter-text {
    flex: 50%;
}
.scooter-price {
    flex: 50%;
    text-align: right;
}
.scooter-footer {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    padding-top: 10px;
}
.scooter-image {
    padding-left: 3px;
    height: 14px;
    width: 14px;
    vertical-align: middle;
}

.bus-block {
    margin-top: 5px;
    display: flex;
    width: auto;
    min-width: 200px;
    flex-direction: column;
}

.bus-id {
    flex: 5%;
    color: black !important;
}

.bus-arrow {
    flex: 5%;
}

.bus-headedTo {
    flex: 50%;
}
.bus-departureTime {
    flex: 20%;
    text-align: right;
}
