.styled-text-input input {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    padding-left: 16px !important;
    border-radius: 0px !important;
    border: 2px solid var(--dxc-light-gray) !important;
    background-color: #f5f5f5 !important;
    font-family: GT-Walsheim-Pro-Regular, Arial !important;
    font-size: 14px !important;
    box-shadow: none !important;
}

.styled-text-input input::placeholder {
    font-family: GT-Walsheim-Pro-Regular, Arial !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    padding-left: 16px !important;
    color: #939393 !important;
}

.styled-text-input.bp3-disabled input::placeholder {
    font-family: GT-Walsheim-Pro-Regular, Arial !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    padding-left: 16px !important;
    color: #cccccc !important;
}

.styled-text-input input:focus {
    font-family: GT-Walsheim-Pro-Regular, Arial !important;
    border: 2px solid #000000 !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    padding-left: 16px !important;
    box-shadow: none !important;
}

.styled-text-input.incorrect-input input {
    font-family: GT-Walsheim-Pro-Regular, Arial !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
    padding-left: 16px !important;
    border: 2px solid #d0011b !important;
    background-color: #f6ccd0 !important;
    color: black;
}

.input-error-text {
    color: #d0011b;
    font-size: 11px;
    margin-top: 5px;
    float: right;
}
