.custom-toast-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    pointer-events: none;
    padding: 0;
    margin: 0;

    .custom-toast-box {
        width: 260px;
        min-width: 260px;
        min-height: 3rem;
        position: absolute;
        top: 10px;
        left: 10px;
        border-radius: 5px;
        pointer-events: all;
        transition: opacity 0.4s;

        .toast-card {
            box-shadow: 0 0 10px #888;
            min-height: 3rem;

            .toast-text {
                width: 100%;
                text-align: center;
                margin: 0;
                padding: 0;
            }

            .toast-progressbar {
                margin-top: 1rem;
            }

            .toast-abort-btn {
                margin-top: 0.5rem;
                font-size: 0.6rem;
                cursor: pointer;
            }
        }

        .toast-close-btn {
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
        }
    }
}