.notes-wrapper {
    position: relative;
    width: 100%;
    min-height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
}

.note-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem;

    .note-item-title {
        width: 25%;
        font-weight: 700;
        padding: 0;
        margin: 0;
        margin-right: 1rem;
    }

    .note-item-excerpt {
        width: 50%;
        padding: 0;
        margin: 0;
        margin-right: 1rem;
    }

    .note-item-buttons {
        width: 25%;
        display: flex;
        justify-content: flex-end;

        .note-item-controls {
            margin-right: 0.5rem;
            cursor: pointer;
        }
    }
}

.react-quill-wrapper {

    * {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    h1, h2, h3, h4, h5, h6 {
        color: var(--dxc-purple2);
        padding: 0;
        margin: 0;
        margin-bottom: 0.5rem;
    }

    p {
        padding: 0;
        margin: 0;
        margin-bottom: 0.25rem;
    }

    u {
        text-decoration: underline !important;
    }

    s {
        text-decoration: line-through !important;
    }

    ul, ol {
        list-style-position: inside;
        padding: 0.25rem;
    }

    pre {
        background-color: #888;
        padding: 0.25rem;
        border-radius: 3px;
    }
}