.dashboard-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
}

.dashboard-navbar {
    width: 260px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #ddd;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
}

.dashboard-screen {
    width: calc(100% - 260px);
    height: 100%;
    padding: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
}

.dashboard-navbar-card {
    width: 100%;
    min-height: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    transition: 0.4s;

}

.dashboard-navbar-card:hover {
    filter: invert(0.15);
}

.dashboard-navbar-card-text {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
}