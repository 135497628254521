.error-icon-container {
    background-color: #e34843;
    display: inline-block;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.error-icon {
    padding: 10px;
}

.error-card-container {
    border: 2px solid var(--dxc-light-gray);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    position: relative;
}

.error-card-text {
    color: var(--dxc-dark-gray);
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
