.sidebar {
    height: calc(100vh - 60px - 40px);
    position: fixed;
    padding: 20px;
    width: 100vw;
}

.sidebar-tabs {
    display: flex !important;
    justify-content: space-between !important;

    & > *:not(:first-child) {
        flex-grow: 1;
    }
}