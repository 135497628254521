//LEFT/RIGHT SIDE
.controls-and-preview-container {
    margin: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.poster-screen-right-side {
    width: 100%;
    margin-left: 2rem;
    position: relative;
    overflow: auto;
}





//POSTER LIST
.posters-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0rem 0 1rem 0; 
    border-radius: 5px;
}

.poster-item {
    margin: 10px 10px 10px 0;
    position: relative;
}

.poster-item-layover {
    transition: 0.4s;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 260px;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.poster-item-layover:hover {
    opacity: 1;
}