.styled-button {
    background-color: var(--dxc-purple) !important;
    background-image: none !important;
    border-radius: 0 !important;
    color: var(--dxc-white) !important;
    font-family: GT-Walsheim-Pro-Bold;
    font-size: 20px !important;
    transition: background-color 100ms ease !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    height: 60px !important;
    text-transform: uppercase;
    text-align: center !important;
}

.styled-button:hover {
    background-color: var(--dxc-white) !important;
    background-image: none !important;
    border-radius: 0 !important;
    color: var(--dxc-black) !important;
    font-family: GT-Walsheim-Pro-Bold;
    font-size: 20px !important;
    transition: background-color 100ms ease !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    height: 60px !important;
    text-transform: uppercase;
    text-align: center !important;
    border: 2px solid var(--dxc-purple) !important;
}

.styled-button.bp3-disabled {
    background-color: var(--dxc-light-gray) !important;
    background-image: none !important;
    border-radius: 0 !important;
    color: var(--dxc-dark-gray) !important;
    font-family: GT-Walsheim-Pro-Bold;
    font-size: 20px !important;
    transition: background-color 100ms ease !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    height: 60px !important;
    text-transform: uppercase;
    text-align: center !important;
    border: 2px solid var(--dxc-black) !important;
}
