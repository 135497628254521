.admin-tree {
    height: 75vh;
    overflow: auto;
    scrollbar-color: #cccccc white;
    scrollbar-width: thin;
}

.admin-tree::-webkit-scrollbar {
    width: 6px;
    margin-left: 15px;
}

.admin-tree::-webkit-scrollbar-track {
    background: white;
}

.admin-tree::-webkit-scrollbar-thumb {
    background: #cccccc;
}

.loading-node {
    float: right;
}

.clickable {
    cursor: pointer;
}

.main-node {
    font-weight: bold;
}




// .admin-page-content-container {
//     width: calc(100vw - 260px);
//     position: fixed;
//     right: 0;
//     top: 60px;
//     height: calc(100vh - 60px - 40px);
// }
